import styles from "./closeButton.module.scss";
import x from "../../assets/icons/x.svg";
import { getRandomArbitrary } from "../../utils/utils";

export const CloseButton = (
  {
    className,
    disabled = false,
    onClick,
    square = false,
  }: {
    className?: string;
    disabled?: boolean;
    onClick?: () => void;
    square?: boolean;
  } ) => {

  const hackIos = () => {
    setTimeout( () => {
      //@ts-ignore
      document.body.style.zoom = `99.${ getRandomArbitrary( 10, 99 ) }%`;

      setTimeout( () => {
        //@ts-ignore
        document.body.style.zoom = '';
      }, 150 );
    }, 150 );
  }

  const close = () => {
    if ( onClick ) onClick();
    hackIos();
  }

  return (
    <div
      onClick={ close }
      className={ [
        styles.container,
        className,
        disabled && styles.container__disabled,
        square && styles.container__square,
      ].join( " " ) }
    >
      <img src={ x }/>
    </div>
  );
};
